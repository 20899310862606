<template>
  <div></div>
</template>

<script>
export default {
  created() {
    window.location.href = 'https://drive.google.com/file/d/1lSQSQOnZVElsnd-3TCEDE9lXqN7OIZ94/view?usp=sharing';
  },
};
</script>

<style></style>
